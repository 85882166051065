import React from 'react';
import { 
  FileText, 
  Building, 
  CheckCircle, 
  ScrollText, 
  LogIn, 
  GraduationCap 
} from 'lucide-react';

const OnboardingSteps = () => {
  const steps = [
    {
      icon: <FileText className="text-gray-500 w-12 h-12" />,
      title: "Application to Shata",
      description: "Submit your initial application for partnership"
    },
    {
      icon: <Building className="text-gray-500 w-12 h-12" />,
      title: "Business Development Outreach",
      description: "Our team will contact event organizers"
    },
    {
      icon: <CheckCircle className="text-gray-500 w-12 h-12" />,
      title: "Internal Verification",
      description: "Comprehensive application review"
    },
    {
      icon: <ScrollText className="text-gray-500 w-12 h-12" />,
      title: "Terms & Conditions",
      description: "Review and accept partnership agreement"
    },
    {
      icon: <LogIn className="text-gray-500 w-12 h-12" />,
      title: "Login Creation",
      description: "Set up customer access credentials"
    },
    {
      icon: <GraduationCap className="text-gray-500 w-12 h-12" />,
      title: "Partner Training",
      description: "Comprehensive training for event organizers"
    }
  ];

  return (
    <div className="bg-white py-8 md:py-12">
      <div className="container mx-auto px-4">
        <h2 className="text-2xl md:text-4xl font-bold text-center mb-6 md:mb-8 text-black">
          Steps of Onboarding Shata Partner
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6">
          {steps.map((step, index) => (
            <div 
              key={index} 
              className="bg-white rounded-2xl shadow-md hover:shadow-xl p-4 md:p-6 
                         transform transition-all duration-300 hover:scale-105 
                         flex flex-col items-center text-center"
            >
              <div className="mb-3 md:mb-4">
                {React.cloneElement(step.icon, {
                  className: "w-8 h-8 md:w-12 md:h-12 text-gray-500"
                })}
              </div>
              <h3 className="text-lg md:text-xl font-semibold mb-2 md:mb-3 text-orange-500">
                Step {index + 1}: {step.title}
              </h3>
              <p className="text-sm md:text-base text-gray-700">
                {step.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OnboardingSteps;