import React from 'react';
import { 
  BarChart2, 
  DollarSign, 
  UserCheck, 
  Clock 
} from 'lucide-react';

const PartnerBenefits = () => {
  const benefits = [
    {
      icon: BarChart2,
      title: 'Lead Generation',
      description: 'Unlock a steady stream of high-quality leads directly to your business. Our advanced matching algorithm connects you with potential clients actively seeking your services, helping you expand your customer base effortlessly.'
    },
    {
      icon: DollarSign,
      title: 'Maximize Revenue',
      description: 'Transform your business potential with our platform. By providing access to a wider market and optimizing your pricing strategies, we help you increase your revenue streams and profitability with minimal additional effort.'
    },
    {
      icon: UserCheck,
      title: 'Personalized Support',
      description: 'Experience dedicated support tailored to your unique business needs. Our team provides one-on-one guidance, strategic insights, and continuous assistance to help you thrive and grow on our platform.'
    },
    {
      icon: Clock,
      title: 'On-Time Payment',
      description: 'Say goodbye to payment delays. We ensure transparent, reliable, and punctual payments, giving you financial peace of mind. Our streamlined payment system guarantees you receive your earnings quickly and consistently.'
    }
  ];

  return (
    <section className="py-16 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center mb-12 text-orange-600">
          Benefits of being Shata Partner
        </h2>
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
          {benefits.map((benefit, index) => (
            <div 
              key={index} 
              className="bg-white shadow-lg rounded-2xl p-6 transform transition-all duration-300 hover:scale-105 hover:shadow-xl border-2 border-orange-50"
            >
              <div className="flex items-center mb-4">
                <benefit.icon 
                  className="text-orange-600 mr-4" 
                  size={48} 
                />
                <h3 className="text-2xl font-semibold text-gray-800">
                  {benefit.title}
                </h3>
              </div>
              <p className="text-gray-600 leading-relaxed">
                {benefit.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PartnerBenefits;