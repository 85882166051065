import React from "react";

const TrustSection = () => {
  return (
    <div className="flex flex-col items-center justify-center text-center bg-orange-500 w-full text-white py-10 px-4">
      <p className="text-xl md:text-2xl font-bold">
        Built on trust with 100k customers and rapid growth
      </p>
      <span className="mt-2 text-sm font-normal">
        ©2024 Shata Partner
      </span>
    </div>
  );
};

export default TrustSection;
