import React, { useState } from 'react';
import { Plus, Minus } from 'lucide-react';

const FAQSection = () => {
  const [openQuestion, setOpenQuestion] = useState(null);

  const faqs = [
    {
      question: "How do I become a Shata Partner?",
      answer: "To become a Shata Partner, you need to complete our online application, go through our business development review, pass internal verification, and complete partner training."
    },
    {
      question: "What are the requirements for partnership?",
      answer: "We look for event organizers with a proven track record, professional infrastructure, and a commitment to delivering high-quality experiences. Specific requirements may vary by event type and location."
    },
    {
      question: "How long does the onboarding process take?",
      answer: "The typical onboarding process takes 1 week, depending on the completeness of your application and the complexity of your event organization."
    },
    {
      question: "What support do Shata Partners receive?",
      answer: "Partners receive Lead generation, High market cap and maximum revenue."
    },
    {
      question: "Are there any fees associated with becoming a partner?",
      answer: "We have a transparent fee structure based on event size and type. During the onboarding process, our team will provide a detailed breakdown of all potential costs and revenue sharing."
    }
  ];

  const toggleQuestion = (index) => {
    setOpenQuestion(openQuestion === index ? null : index);
  };

  return (
    <div className="bg-white py-12">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-10 text-orange-600">
          Frequently Asked Questions
        </h2>
        <div className="max-w-3xl mx-auto space-y-5">
          {faqs.map((faq, index) => (
            <div 
              key={index} 
              className="border border-orange-100 rounded-2xl overflow-hidden 
                         transition-all duration-300 hover:shadow-lg"
            >
              <button 
                onClick={() => toggleQuestion(index)}
                className="w-full flex justify-between items-center 
                           p-5 text-left bg-white 
                           hover:bg-orange-50 transition-colors duration-200
                           focus:outline-none focus:ring-2 focus:ring-orange-300"
              >
                <h3 className="text-lg md:text-xl font-regular text-black pr-4">
                  {faq.question}
                </h3>
                <div className="flex-shrink-0">
                  {openQuestion === index ? (
                    <Minus className="text-orange-600 w-6 h-6" />
                  ) : (
                    <Plus className="text-orange-600 w-6 h-6" />
                  )}
                </div>
              </button>
              {openQuestion === index && (
                <div 
                  className="px-5 pb-5 text-gray-700 text-base md:text-lg 
                             bg-white border-t border-orange-100
                             animate-fade-in-down"
                >
                  {faq.answer}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQSection;