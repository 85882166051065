import React, { useState } from 'react';
import { PhoneIcon, ShieldCheckIcon } from 'lucide-react';

const AuthPage = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [isCodeSent, setIsCodeSent] = useState(false);

  const handlePhoneSubmit = (e) => {
    e.preventDefault();
    const phoneRegex = /^\+?[1-9]\d{1,14}$/;
    if (phoneRegex.test(phoneNumber)) {
      setIsCodeSent(true);
    } else {
      alert('Please enter a valid phone number');
    }
  };

  const handleVerificationSubmit = (e) => {
    e.preventDefault();
    if (verificationCode.length === 6 && /^\d+$/.test(verificationCode)) {
      alert('Verification successful!');
    } else {
      alert('Please enter a valid 6-digit code');
    }
  };

  return (
    <div className="min-h-screen flex flex-col relative">
      {/* Background Image */}
      <div className="absolute inset-0 z-0">
        <img 
          src="/14.jpg" 
          alt="Background" 
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-orange-500 opacity-70"></div>
      </div>

      {/* Content Container */}
      <div className="relative z-10 flex-grow flex items-center justify-center px-4 py-20 md:py-0">
        <div className="w-full max-w-5xl flex flex-col md:flex-row bg-white/90 rounded-2xl overflow-hidden shadow-2xl">
          {/* Left Side - Text Content */}
          <div className="w-full md:w-1/2 bg-orange-500 text-white flex items-center justify-center p-8 md:p-12">
            <div className="text-center md:text-left max-w-md">
              <h2 className="text-2xl md:text-4xl font-bold mb-4">Verified Shata Partner</h2>
              <p className="text-base md:text-xl mb-6">
                100k customers with rapid spread of cities and lead generation
              </p>
              <button className="bg-white text-orange-600 px-6 py-3 rounded-lg hover:bg-orange-50 transition-colors">
                Become a Shata Partner
              </button>
            </div>
          </div>

          {/* Right Side - Phone Authentication */}
          <div className="w-full md:w-1/2 flex items-center justify-center p-6 md:p-12">
            <div className="w-full max-w-md">
              {/* Header for Authentication */}
              <div className="bg-orange-500 text-white p-6 text-center rounded-t-xl">
                <div className="flex justify-center mb-4">
                  <PhoneIcon size={48} className="text-white" />
                </div>
                <h2 className="text-2xl font-bold">Phone Verification</h2>
                <p className="text-orange-100 text-sm">Secure your account with phone authentication</p>
              </div>
              
              {/* Form Container */}
              <div className="bg-white p-6 sm:p-8 rounded-b-xl shadow-lg">
                {!isCodeSent ? (
                  <form onSubmit={handlePhoneSubmit}>
                    <div className="mb-4">
                      <label className="block text-orange-700 mb-2 font-medium">Phone Number</label>
                      <input 
                        type="tel" 
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        placeholder="+1 (___) ___-____"
                        className="w-full p-3 border-2 border-orange-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
                        required 
                        pattern="^\+?[1-9]\d{1,14}$"
                      />
                    </div>
                    <button 
                      type="submit" 
                      className="w-full bg-orange-500 text-white p-3 rounded-lg hover:bg-orange-600 transition-colors font-semibold"
                    >
                      Send Verification Code
                    </button>
                  </form>
                ) : (
                  <form onSubmit={handleVerificationSubmit}>
                    <div className="mb-4 flex items-center">
                      <ShieldCheckIcon size={32} className="text-orange-500 mr-3" />
                      <label className="block text-orange-700 font-medium">Verification Code</label>
                    </div>
                    <input 
                      type="text" 
                      value={verificationCode}
                      onChange={(e) => setVerificationCode(e.target.value)}
                      placeholder="Enter 6-digit code"
                      maxLength={6}
                      className="w-full p-3 border-2 border-orange-300 rounded-lg mb-4 focus:outline-none focus:ring-2 focus:ring-orange-500"
                      required 
                      pattern="\d{6}"
                    />
                    <button 
                      type="submit" 
                      className="w-full bg-orange-500 text-white p-3 rounded-lg hover:bg-orange-600 transition-colors font-semibold"
                    >
                      Verify Code
                    </button>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthPage;